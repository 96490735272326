import { render, staticRenderFns } from "./about-history.vue?vue&type=template&id=ca43abe4&scoped=true&"
import script from "./about-history.vue?vue&type=script&lang=js&"
export * from "./about-history.vue?vue&type=script&lang=js&"
import style0 from "./about-history.vue?vue&type=style&index=0&id=ca43abe4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca43abe4",
  null
  
)

export default component.exports